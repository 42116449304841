
import { useEffect, useState } from "react";

import { Alchemy, Network } from 'alchemy-sdk';

import env from "react-dotenv";
import { isMobile } from 'react-device-detect';
import { ethers } from "ethers";
import ProductForm from './product-form';
import customData from './abi.json';
import MultiVariantProduct from './multiVariantProduct';

import DynamoDB from 'aws-sdk/clients/dynamodb';



const dbb = new DynamoDB({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, region: 'us-west-2' });
require('dotenv').config();




// var params = {
//   TableName: 'A-NFT',
//   Key: {
//     'a-nft-key': { S: "0x00000000000000000000000000000000003" }
//   }
// };

// // Call DynamoDB to read the item from the table
// dbb.getItem(params, function (err, data) {
//   if (err) {
//     //console.log("Error", err);
//   } else {
//     console.log("Success", data.Item);
//     if (data.Item != undefined) {
//       //console.log("TRUE!");
//     }
//     else {
//       //console.log("False!");
//     }
//   }
// });

// const provider = new ethers.providers.Web3Provider(window.ethereum);
//           //await provider.send("eth_requestAccounts", []);
          

//           const signer = provider.getSigner();
//           const contract = new ethers.Contract("0xdd9d52d87c1d594a3c9f071330ffa2997926c268",customData.abi,signer);

          

const apiKey = process.env.REACT_APP_TOKEN_APIKEY;


const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [validWallet, setValidWallet] = useState("");
  const [maintokenID, setMainTokenID] = useState("");

  var ageMonth = "";
  var ageDay = "";
  var ageYear = "";

  var burnID = "";


  var browser = "";
  if (isMobile) {
    var browser = "TRUE";
  }
  else {
    var browser = "FALSE";
  }

  //https://eth-mainnet.alchemyapi.io/v2/${apiKey} eth-goerli.
  //  const web3 = createAlchemyWeb3(
  //    `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`,
  //  );
  const settings = {
    apiKey: apiKey,
    network: Network.ETH_MAINNET,
  };

  const web3 = new Alchemy(settings);
  
  
  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  //         //await provider.send("eth_requestAccounts", []);
          

  // const signer = provider.getSigner();
  // var abi = "";


  


  //const contract = new ethers.Contract("0xdd9d52d87c1d594a3c9f071330ffa2997926c268","",signer);
  //console.log(JSON.stringify(contract));

  useEffect(() => {
    // wrap your async call here
    const loadData = async () => {

      //buildAgeForm();

      

      // fetch('https://api-goerli.etherscan.io/api?module=contract&action=getabi&address=0xac63f537964b0c98141bdd676c7ae12d5de7788b')
      //   .then(response => response.json())
      //   .then(data => {
      //       console.log("DATA: "+JSON.stringify(customData.result));
      //       const contract = new ethers.Contract("0xdd9d52d87c1d594a3c9f071330ffa2997926c268",customData.result,"0x2231b0188dad7349695dc84b8fe5d1bee5e79cfe");
      //       // console.log(JSON.stringify(contract));
      //   });

      //document.addEventListener('contextmenu', event => event.preventDefault());
      // const { address, status } = await getCurrentWalletConnected();
      // setWallet(address)
      // setStatus(status);

      home();

      addWalletListener();

    };

    // then call it here
    loadData();
  }, []);





  const connectWalletPressed = async () => {
    await connectWallet().then((res) => {
      if(res){
        if(res.status != null && res.status != ""){setStatus(res.status);}
        if(res.address != null && res.status != ""){
        setWallet(res.address);
        verifyWallet(res.address);
        }
     }
    });
    
   

    

  };



  const tryGetJson = async (resp) => {
    return new Promise((resolve) => {
      if (resp) {
        resp.json().then(json => resolve(json)).catch(() => resolve(null))
      } else {
        resolve(null)
      }
    })
  }

  const connectWallet = async () => {
    if (window.ethereum) {
      console.log("FOUND ETH");
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verifying...</h1>,
          address: addressArray[0],
        };

        return obj;
      } catch (err) {
        console.log("ERROR CONNECTING: "+err);
        // return {
        //   address: "",
        //   status: (<div>
        //     <div className="redirect-section landing-block">
        //       <div className="text-block-parent">


        //       <div className="text-image-section-wrapper">
        //         <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
        //       </div>
        //         <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
               
        //         <div className="token-check-parent">
        //           <div>
        //           <button id="walletButton" onClick={connectWalletPressed}>
        //               {walletAddress.length > 0 ? (
        //                 "Connected: " +
        //                 String(walletAddress).substring(0, 6) +
        //                 "..." +
        //                 String(walletAddress).substring(38)
        //               ) : (
        //                 <span>Connect</span>
        //               )}
        //             </button>

        //             {/* <a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a> */}
        //           </div>
        //         </div>
        //       </div>
        //       <div className="image-section-wrapper">
        //         <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
        //       </div>
        //     </div>
        //   </div>)
        // };
      }
    } else {
      return {
        address: "",
        status: (
          <div>
                 <div className="redirect-section landing-block">
                   <div className="text-block-parent">


                   <div className="text-image-section-wrapper">
                     <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
                   </div>
                     <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                    
                    {/* <div className="token-check-parent">
                       <div>
                       <button id="walletButton" >
                           {walletAddress.length > 0 ? (
                             "Connected: " +
                             String(walletAddress).substring(0, 6) +
                             "..." +
                             String(walletAddress).substring(38)
                           ) : (
                             <span>Connect Wallet</span>
                           )}
                         </button>

                          //<a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a>
                       </div>
                     </div>  */}
                   </div>
                   <div className="image-section-wrapper">
                     <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
                   </div>
                 </div>
               </div>
        ),
      };
    }
  };

  const getCurrentWalletConnected = async () => {
    try {
      if (window.ethereum) {
        console.log("FOUND ETH");
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_accounts",
          });
          if (addressArray.length > 0) {


            return {
              address: addressArray[0]
              // status: 
              // <div>
              //   <div className="redirect-section landing-block">
              //     <div className="text-block-parent">


              //     <div className="text-image-section-wrapper">
              //       <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
              //     </div>
              //       <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                    
              //       <div className="token-check-parent">
              //         <div>
              //           <button id="walletButton" onClick={connectWalletPressed}>
              //             {walletAddress.length > 0 ? (
              //               "Connected: " +
              //               String(walletAddress).substring(0, 6) +
              //               "..." +
              //               String(walletAddress).substring(38)
              //             ) : (
              //               <span>Connect</span>
              //             )}
              //           </button>

              //           {/* <a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a> */}
              //         </div>
              //       </div>
              //     </div>
              //     <div className="image-section-wrapper">
              //       <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
              //     </div>
              //   </div>
              // </div>,
            };



          } else {
            
            return {
              address: "",
              status: <div>
              <div className="redirect-section landing-block">
                <div className="text-block-parent">


                <div className="text-image-section-wrapper">
                  <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
                </div>
                  <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                  
                  {/*<div className="token-check-parent">
                    <div>
                      <button id="walletButton" >
                        {walletAddress.length > 0 ? (
                          "Connected: " +
                          String(walletAddress).substring(0, 6) +
                          "..." +
                          String(walletAddress).substring(38)
                        ) : (
                          <span>Connect Wallet</span>
                        )}
                      </button>

                        //<a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a>  
                    </div>
                  </div>*/}
                </div>
                <div className="image-section-wrapper">
                  <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
                </div>
              </div>
            </div>,
            };
          }
        } catch (err) {
          return {
            address: "",
            status: "😥 " + JSON.stringify(err.message),
          };
        }
      } else {
        if (isMobile) {
          return {
            address: "",
            status: (
              <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Browsing on mobile?</h1>
                <p className="paragraph">Click the button below to open this webpage in the Metamask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/the-underground-claim.herokuapp.com/" >
                  Open in Metamask
                </a>
              </div>
            ),
          };
        }
        else {
          return {
            address: "",
            status: (
              <div className="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">No Metamask extension found</h1>
                <p className="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                  Download Metamask
                </a>
              </div>
            ),
          };
        }
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const onMintPressed = async () => { //TODO: implement

  };



  const verifyWallet = async (mainAddress) => {
    //const { address, status } = await getCurrentWalletConnected();
    const address = mainAddress;
    // web3.core.getTransaction("0x6cddcb2e7aed2ac134981bd8a3e19a0605cef6263bf86ff695cd2cabcaa4cb4e").then((res) =>{
    //   console.log("Transaction: "+ JSON.stringify(res)+res.status);
    // });
    //console.log("Signer: ++"+ address);
    
    
    
    //  dbb.putItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //     }
    //     else{
    //       console.log("False!");
    //     }
    //   }
    // });
    //console.log(" Address: "+address.length);
    try {
      if (address.length == 0) {
        //connectWalletPressed();
        // setWallet("");
        // setStatus(
        //   <div>
        //         <div className="redirect-section landing-block">
        //           <div className="text-block-parent">


        //           <div className="text-image-section-wrapper">
        //             <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
        //           </div>
        //             <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                    
        //             <div className="token-check-parent">
        //               <div>
        //                 <button id="walletButton" >
        //                   {walletAddress.length > 0 ? (
        //                     "Connected: " +
        //                     String(walletAddress).substring(0, 6) +
        //                     "..." +
        //                     String(walletAddress).substring(38)
        //                   ) : (
        //                     <span>Connect</span>
        //                   )}
        //                 </button>

        //                 {/* <a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a> */}
        //               </div>
        //             </div>
        //           </div>
        //           <div className="image-section-wrapper">
        //             <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
        //           </div>
        //         </div>
        //       </div>
        // );
        return false;
      }

      else {
        //const nfts = await web3.alchemy.getNftsForOwner({owner: address});  0xde747bb0a3d351c243073b56e117b7b5605913f8

        //console.log("DATA: "+JSON.stringify(customData.result + " WALLET: "+ web3.Wallet));
        //console.log( " WALLET: "+ JSON.stringify(web3));
            //const contract = new ethers.Contract("0xdd9d52d87c1d594a3c9f071330ffa2997926c268",customData.result,web3.Wallet);

            

          //var returnValue = await contract.burnBatch("0x2231B0188dad7349695DC84B8FE5D1bee5E79cFE",[1],[1]);
          //console.log(" CONTRACT: "+ JSON.stringify(returnValue));

          

          //contract.contractURI;


        const nfts = await web3.nft.getNftsForOwner(address, {
          contractAddresses: ["0xd58423fd30b1bb193715bd60b3397065772f7afd"]
        });
        //console.log("ADDRESS: --- " + JSON.stringify(nfts));
        var foundNFT = false;
        // var retString = "";
        // Print owner's wallet address:
        // retString = retString + "fetching NFTs for address: ";
        // retString = retString + address;
        // retString = retString + "\n...\n";

        // Print total NFT count returned in the response:
        // retString = retString + "number of NFTs found:";
        // retString = retString + nfts.totalCount;
        // retString = retString + "...";


        if (address == "0x2231b0188dad7349695dc84b8fe5d1bee5e79cfe" || address == "0x282672eda77623459c49066c1531dde8edb5bd75" || address == "0x9072831edb5d98c49a8358045b19249002a0286a" || address == "0xfe3e57a3c93c2adbb22dd23cb30a31e06c055eb9") {

          foundNFT = true;
        }

        var tokens = [];

        // Print contract address and tokenId for each NFT:
        for (const nft of nfts.ownedNfts) {
          // retString = retString + "===";
          // retString = retString + "contract address:";
          // retString = retString + nft.contract.address;
          // retString = retString + "token ID:";
          // retString = retString + nft.id.tokenId;

          //     0x7fea34e2dc6f63ccd8465cef3dd616db0762759f

          
          //// await contract.balanceOf(address,[0]).then(async (res) => {
          ////   console.log("INITIAL: "+ res);
          //// });
          console.log(" TOKEN ++ "+ JSON.stringify(nft) );
          if (nft.contract.address == "0xd58423fd30b1bb193715bd60b3397065772f7afd") {

            //retString = retString + "FOUND NFT";
            foundNFT = true;
            tokens.push(nft);
          }



          // const response = await web3.alchemy.getNftMetadata({
          //   contractAddress: "0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d",
          //   tokenId: nft.id.tokenId
          // });
          // var resString = JSON.stringify(response)
          // retString = retString + resString;
        }
        // const response = await web3.alchemy.getNftMetadata({
        //     contractAddress: "0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d"
        //   });
        //   var resString = JSON.stringify(response);

        // console.log(resString);



        if (foundNFT == true) {

          allowWallet();

          const list = document.createElement("div");
          list.classList.add('nfts-parent');
          const nfts = await web3.nft.getNftsForOwner(address, {
            contractAddresses: ["0xd58423fd30b1bb193715bd60b3397065772f7afd"]
          });
          for (const nft of nfts.ownedNfts) {
            //console.log("URI: " + JSON.stringify(nft.tokenUri.gateway));

            var params = {
              TableName: 'Short-IDs',
              Key: {
                'Short-ID': { S: ethers.BigNumber.from(nft.tokenId).toString() }
              }

            };

            
                let tokenName = nft;
                //console.log("This NFT: ++ " + JSON.stringify(nft));


                let child = document.createElement("div");
                child.classList.add('nft-child');
                let imageParent = document.createElement("div");
                imageParent.classList.add('imageParent');
                let image = document.createElement("img");
                let customName = tokenName.tokenId;
                let type = "";
                
                 
                  image.src = "bag-square.gif"; //"card.png";nft.media[0].thumbnail
                
                

                image.classList.add('nft-image');
                imageParent.appendChild(image);
                let title = document.createElement("h3");
                title.classList.add('nft-title');
                
                title.innerText = "#"+customName;
                let idOfToken = document.createElement("p");
                idOfToken.classList.add('tokenID');
          
                idOfToken.innerText = "Token ID: "+ethers.BigNumber.from(tokenName.tokenId).toString();
                let quantOfToken = document.createElement("p");
                quantOfToken.classList.add('tokenID');
                quantOfToken.innerText = "Balance: "+tokenName.balance;
                let buttonParent = document.createElement("div");
                buttonParent.classList.add('buttonParent');
                let button = document.createElement("button");
                button.classList.add('nft-button');


                button.innerText = "Claim Reward"
                button.addEventListener("click", function () {
                  multiVariant(tokenName, address);
                });

                

               
                buttonParent.appendChild(button);
                child.appendChild(imageParent);
                child.appendChild(title);
                
                child.appendChild(buttonParent);
                list.appendChild(child);

            


          }
          let parent = document.getElementById("nfts-container");
          parent.innerHTML = "";
          parent.appendChild(list);



        }
        else {
          denyWallet();

        }

        return foundNFT;
      }

    } catch (err) {
      console.log(err);
    }
  }




  //  function handleAgeChange(event){
  //    console.log("EVENT HERE: "+event.target.value);
  //    setMonth("09");
  //    ageMonth = "09";
  //    (console.log("New Month: "+month + "THis Month: " + ageMonth));
  //  }
  const handleAgeSubmit = async (event) => {
    // event.preventDefault();
    // var date = new Date();

    // var old = "false";

    // //console.log(event.target[0].value + " Today Date: "+ date);
    // if (Number(event.target[2].value) >= 2001) {
    //   if (Number(event.target[2].value) == 2001) {
    //     if (Number(event.target[0].value) - 1 >= date.getMonth()) {
    //       if (Number(event.target[0].value) - 1 == date.getMonth()) {
    //         if (Number(event.target[1].value) >= date.getDate()) {
    //           if (Number(event.target[1].value) == date.getDate()) {
    //             old = "true";
    //           }
    //         }
    //         else {
    //           old = "true";
    //         }
    //       }
    //     }
    //     else {
    //       old = "true";
    //     }
    //   }
    // }
    // else {
    //   old = "true";
    // }
    // if (event.target[2].value == "" || event.target[1].value == "" || event.target[0].value == "") {
    //   old = "false";
    // }

    // if (old == "true") {
    //   const { address, status } = await getCurrentWalletConnected();
    //   setWallet(address)
    //   setStatus(status);


    //   addWalletListener();
    // }
  }

  function buildAgeForm() {

    setStatus(
      <div>
        <h1 className="age-form">YOU MUST BE OF LEGAL DRINKING<br></br>AGE TO ENTER THIS SITE</h1>

        <form className="age-form" onSubmit={handleAgeSubmit}>

          <div className='age-form-imput-parent'>
            <input
              type="number"
              className='month-input'
              name={"month"}
              placeholder={"MM"}

            />
            <input
              type="number"
              className='day-input'
              name={"day"}
              placeholder={"DD"}

            />
            <input
              type="number"
              className='year-input'
              name={"year"}
              placeholder={"YY"}

            />
            <button className="age-button" type="submit" >ENTER</button>
          </div>


        </form>
      </div>
    );
  }



  function tokenChecker() {
    setStatus(
      <div className="verified-parent">
        <div className="redirect-section">
          <div className="text-parent-checker">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">TOKEN CHECKER</h1>
            <p className="paragraph">Enter a token ID to verify if it is eligible for the claimable merch kit</p>
          </div>
          <div className="checker-form">
            <div className="input-parent-checker"><input type="text" className="token-checker-input" placeholder="Enter token ID"></input></div>
            <button id="walletButton" className="checker-btn" onClick={checking} >SUBMIT</button>
          </div>
          <div className="token-check-parent">
            <a className="token-checker-btn" onClick={home}>Continue to claim</a>
          </div>

        </div>
      </div>
    );
  }

  function checking() {
    var toCheck = document.getElementsByClassName("token-checker-input")[0];

    var params = {
      TableName: 'Short-IDs',
      Key: {
        'Short-ID': { S: toCheck.value }
      }

    };
    //console.log("Value: "+ toCheck.value);

    // Call DynamoDB to read the item from the table
    dbb.getItem(params, function (err, data) {
      if (err) {
        //console.log("Error", err);
      } else {
        //console.log("Success", data.Item);
        if (data.Item == undefined) {

          setStatus(
            <div className="verified-parent">
              <div className="redirect-section">
                <div className="text-parent-checker">
                  <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">TOKEN CHECKER</h1>
                  <p className="paragraph">Enter a token ID to verify if it is eligible for the claimable merch kit</p>
                </div>
                <div className="response-block">

                  <p className="paragraph response-text">Token ID entered is <span className="isvalid">eligible</span> to claim!</p>
                </div>

                <div className="token-check-parent">
                  <a className="token-checker-btn" onClick={home}>Continue to claim</a>
                </div>

              </div>
            </div>
          );
        }
        else {

          setStatus(
            <div className="verified-parent">
              <div className="redirect-section">
                <div className="text-parent-checker">
                  <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">TOKEN CHECKER</h1>
                  <p className="paragraph">Enter a token ID to verify if it is eligible for the claimable merch kit</p>
                </div>
                <div className="response-block">
                  <p className="paragraph response-text">Token ID entered is <span className="invalid">not eligible</span> to claim!</p>

                </div>

                <div className="token-check-parent">
                  <a className="token-checker-btn" onClick={home}>Continue to claim</a>
                </div>

              </div>
            </div>
          );
        }
      }
    });



  }

  function home() {
    setStatus(
      <div>
                <div className="redirect-section landing-block">
                  <div className="text-block-parent">
                    <div className="text-image-section-wrapper">
                      <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
                    </div>
                      <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                      
                      {/* <div className="token-check-parent">
                        <div>
                          <button id="walletButton" >
                            {walletAddress.length > 0 ? (
                              "Connected: " +
                              String(walletAddress).substring(0, 6) +
                              "..." +
                              String(walletAddress).substring(38)
                            ) : (
                              <span>Connect Wallet</span>
                            )}
                          </button>

                          //<a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a> 
                        </div>
                      </div> */}
                    </div>
                    <div className="image-section-wrapper">
                      <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
                    </div>
                  </div>
                </div>
    );
  }


   function checkTransaction(hash) {
    web3.core.getTransaction(hash).then((res) => {
        console.log("Transaction: "+ JSON.stringify(res)+res.status);
        if(res.blockHash){
          var status = res.blockHash;
          if(status != "" && status != null) {
            console.log("FOUND SUCCESS");
            console.log("")
          }
          else {
            setTimeout(() => {
              console.log("Delayed for 1 second.");
              checkTransaction(hash);
            }, 4000);
            
          }
        }
      });
  }







  

 


  

  

  

  async function burnToken () {

    // this.contract.burnBatch(this.props.address,[this.props.tokenid],[1]).then( (res) => {


    //   console.log("RESPONSE: "+JSON.stringify(res));

    //   var params = {
    //     TableName: 'Transactions',
    //     Item: {
    //       'transaction': {S: res.hash},
    //       'tokenId': {S: this.props.tokenid},
    //       'walletAddress': {S: this.props.address}
    //     }
        
    //   };
      
      
    //   this.dbb.putItem(params, function(err, data) {
    //     if (err) {
    //       console.log("Error", err);
    //     } else {
    //       console.log("Success");
          
    //     }
    //   });

    //   this.checkTransaction(res.hash);
    //   this.props.loadingFunction();


    //  })
}



  async function multiVariant(tokenName, address) {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    let descrip = {"text":<span></span>};

      setStatus(
        <div className="redirect-section">
              
            <MultiVariantProduct descrip={descrip} address={address} tokenid={tokenName.tokenId} url={tokenName} loadingShipping={loadingShippingFunction} buttonFunction={successFunction} errorFunction={errorFunction} waitForBurn={waitForBurn} loadingFunction={loadingFunction}></MultiVariantProduct>
          </div>
      );
      



  }




  async function productThird() {
    //console.log("TOKEN ID: "+burnID);
    //const { address, status } = await getCurrentWalletConnected();
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // setStatus(
    //   <div className="verified-parent">
    //     <div className="redirect-section">
    //       <div>
    //         <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile product">IN YOUR KIT</h1>
    //         <p className="product-container-label">PRODUCT <span> 3 </span> OF <span> 3</span></p>
    //       </div>
    //       <div className="product-container">
    //         <div className="split-parent">
    //           <div className="split-child">
    //             <video src="https://cdn.shopify.com/videos/c/o/v/40607bb99bc44ce4899339ed7d65191d.mp4" width="320" height="240" autoPlay={true} loop={true} controls={false} playsInline={true} />
    //           </div>
    //           <div className="split-child">
    //             <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile product title-product third">adidas WAGMI<br></br>United Football Jersey</h1>
    //             <p className="sub-heading">Free to claim, just pay shipping & duties (if applicable)</p>
    //             <p className="paragraph description">Created for high performance, this adidas Condivo 22 jersey is all about comfortable movement.  It's ventilating mesh side panels keep you cool while you are performing.  Working hard to wick moisture from your skin, AEROREADY ensures you're always ready for more. Made with 100% recycled materials, this product represents just one of our solutions to help end plastic waste.<br></br><br></br>Details:<br></br>• Slim fit<br></br>• Ribbed V-neck<br></br>• 100% recycled polyester<br></br>• Moisture-absorbing AEROREADY<br></br>• Imported</p>
    //             <ProductForm tokenid={burnID} dynamodb={dbb} sizeChart={toggleSizeChart} wallet={address} error={errorFunction}></ProductForm>
    //           </div>
    //         </div>
    //       </div>
    //       {/* <img src="size-chart.png" loading="lazy" sizes=""  alt="" className="product-img"></img> */}
    //       <div id="size-chart-target" className="sizechart-parent hidden" onClick={closeSizeChart}>
    //         <div className="size-parent-container">
    //           <div className="modal-size-chart">
    //             <h1>Size Chart</h1>
    //             <div className="table">
    //               <div className="table-row">
    //                 <div className="table-cell first">Size</div>
    //                 <div className="table-cell">Chest (in.)</div>
    //                 <div className="table-cell">Waist (in.)</div>
    //                 <div className="table-cell">Hip (in.)</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">XS</div>
    //                 <div className="table-cell">32.5 - 34</div>
    //                 <div className="table-cell">27.5 - 29</div>
    //                 <div className="table-cell">32 - 33.5</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">S</div>
    //                 <div className="table-cell">34.5 - 36</div>
    //                 <div className="table-cell">29.5 - 31.5</div>
    //                 <div className="table-cell">34 - 36</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">M</div>
    //                 <div className="table-cell">36.5 - 39</div>
    //                 <div className="table-cell">32 - 34.5</div>
    //                 <div className="table-cell">36.5 - 39</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">L</div>
    //                 <div className="table-cell">39.5 - 42.5</div>
    //                 <div className="table-cell">35 - 38</div>
    //                 <div className="table-cell">39.5 - 42</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">XL</div>
    //                 <div className="table-cell">43 - 46.5</div>
    //                 <div className="table-cell">38.5 - 42</div>
    //                 <div className="table-cell">42.5 - 45.5</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">2XL</div>
    //                 <div className="table-cell">47 - 51</div>
    //                 <div className="table-cell">42.5 - 47</div>
    //                 <div className="table-cell">46 - 49</div>
    //               </div>
    //               <div className="table-row">
    //                 <div className="table-cell first">3XL</div>
    //                 <div className="table-cell">51.5 - 56</div>
    //                 <div className="table-cell">47.5 - 52</div>
    //                 <div className="table-cell">49.5 - 53</div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="close">x</div>
    //         </div>

    //       </div>

    //     </div>
    //   </div>
    //);
  }

  function toggleSizeChart() {
    var target = document.getElementById("size-chart-target");
    //console.log("Target: "+target.classList.contains("hidden"));
    if (target.classList.contains("hidden")) {
      target.classList.remove("hidden");
    }

  }
  function closeSizeChart() {
    var target = document.getElementById("size-chart-target");
    //console.log("Target: "+target.classList.contains("hidden"));
    if (target.classList.contains("hidden")) {

    }
    else {
      target.classList.add("hidden");
    }
  }

  function loadingShippingFunction() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Processing Shipping Transaction...</h1>
        <div className="warning-section">
          <h4>DO NOT REFRESH THIS PAGE</h4>
          <p className="paragraph">This process can take a few moments.</p>
        </div>
        
      </div>
    );
  }
  function loadingFunction() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Processing Burn...</h1>
        <div className="warning-section">
          <h4>DO NOT REFRESH THIS PAGE</h4>
          <p className="paragraph">This process can take a few moments.</p>
        </div>
      </div>
    );
  }
  function waitForBurn() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Burning Token...</h1>
        <div className="warning-section">
          <h4>DO NOT REFRESH THIS PAGE</h4>
          <p className="paragraph">Please confirm the burn of your selected token</p>
        </div>
        
      </div>
    );
  }
  function errorFunction() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Error!</h1>
        <p className="paragraph">There was an error verifying that you own this token</p>
      </div>
    );
  }
  function successFunction() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">CLAIM SUCCESS</h1>
        <p className="paragraph">You have successfully claimed your merch bundle!<br></br>You will receive an email confirmation shortly with details.</p>
        <button id="walletButton" onClick={connectWalletPressed}><span>View Remaining Tokens</span></button>
      </div>
    );
  }


  //   const sendOrder = async () => {
  //     const client = new Shopify.Clients.Rest('ef-testing.myshopify.com', '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');
  //     const data = await client.post({
  //       path: 'orders',
  //       data: {"order":{"line_items":[{"variant_id":39986963742884,"quantity":1}],"customer":{"first_name":"Josh","last_name":"Maldonado","email":"joshm@topdrawermerch.com"},"billing_address":{"first_name":"Josh","last_name":"Maldonado","address1":"6613 Warbler Ln","phone":"555-555-5555","city":"Colorado Springs","country":"United States","zip":"80919"},"shipping_address":{"first_name":"Josh","last_name":"Maldonado","address1":"6613 Warbler Ln","phone":"777-777-7777","city":"Colorado Springs","country":"United States","zip":"80919"},"email":"joshm@topdrawermerch.com"}},
  //       type: DataType.JSON,
  //     });

  //     console.log(JSON.stringify(data));
  //   }

  const sendOrder = async () => {
    fetch('https://hooks.zapier.com/hooks/catch/5494090/beccz65/', {
      method: 'POST',
      body: JSON.stringify({
        "order": { "line_items": [{ "variant_id": 39986963742884, "product_id": 6729147187364, "name": "Test Tee 1", "quantity": 1 }], "customer": { "first_name": "Josh", "last_name": "Maldonado", "email": "joshm@topdrawermerch.com" }, "billing_address": { "first_name": "Josh", "last_name": "Maldonado", "address1": "6613 Warbler Ln", "phone": "555-555-5555", "state": "Colorado", "city": "Colorado Springs", "country": "United States", "zip": "80919" }, "shipping_address": { "first_name": "Josh", "last_name": "Maldonado", "address1": "6613 Warbler Ln", "phone": "777-777-7777", "city": "Colorado Springs", "state": "Colorado", "country": "United States", "zip": "80919" }, "email": "joshm@topdrawermerch.com" },
      }),

    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        // Handle data
      })
      .catch((err) => {
        //console.log(err.message);
      });

  }

  function clearScreen() {
    setStatus(
      <div className="verified-parent">
        <div className="redirect-section">
          <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Select Token</h1>
          <p className="paragraph token-select-p">Select a claimable token below to begin the reward claim process.</p>

        </div>




      </div>
    );

  }

  function allowWallet() {

    //sendOrder();
    //  const script = document.createElement('script');

    //  script.src = "https://form.jotform.com/jsform/222568031828155";
    //  script.async = true;
    //  script.type = "text/javascript"
    //  document.body.appendChild(script);

    setStatus(
      <div className="verified-parent">
        <div className="redirect-section">
          <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Select Token</h1>
          <p className="paragraph token-select-p">Select a claimable token below to begin the reward claim process.</p>

        </div>
        <div id="nfts-container"></div>



      </div>
    );



  }

  function denyWallet() {
    setStatus(
      <div className="redirect-section">
        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Sorry!</h1>
        <p className="paragraph">There are no eligible tokens found in this wallet. Open MetaMask or Coinbase and connect to a different wallet.</p>

      </div>
    );
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(<h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Verifying...</h1>);

          verifyWallet(accounts[0]);


        } else {
          connectWalletPressed();
          // setWallet("");
          // setStatus(
          //   <div>
          //       <div className="redirect-section landing-block">
          //         <div className="text-block-parent">


          //         <div className="text-image-section-wrapper">
          //           <img src="home-text.png" loading="lazy" sizes="" alt="" className="image-slide"></img>
          //         </div>
          //           <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile connect">Claim Closed</h1>
                    
          //           <div className="token-check-parent">
          //             <div>
          //               <button id="walletButton" onClick={connectWalletPressed}>
          //                 {walletAddress.length > 0 ? (
          //                   "Connected: " +
          //                   String(walletAddress).substring(0, 6) +
          //                   "..." +
          //                   String(walletAddress).substring(38)
          //                 ) : (
          //                   <span>Connect</span>
          //                 )}
          //               </button>

          //               {/* <a id="walletButton" className="token-checker-btn" onClick={tokenChecker}>Check a token</a> */}
          //             </div>
          //           </div>
          //         </div>
          //         <div className="image-section-wrapper">
          //           <img src="hat.webp" loading="lazy" sizes="" alt="" className="image-slide"></img>
          //         </div>
          //       </div>
          //     </div>
          // );
        }
      });
    } else {
      if (isMobile) {
        setStatus(
          <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">Browsing on mobile?</h1>
            <p className="paragraph">Click the button below to open this webpage in the Metamask Browser</p>
            <a id="walletButton" href="https://metamask.app.link/dapp/the-underground-claim.herokuapp.com/" >
              Open in Metamask
            </a>
          </div>
        );
      }
      else {
        setStatus(
          <div className="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile">No Metamask extension found</h1>
            <p className="paragraph">Click the button below to download Metamask to supporting browsers</p>
            <a id="walletButton" href="https://metamask.io/download.html" >
              Download Metamask
            </a>
          </div>
        );
      }
    } 
  }

  return (
    <div className="Minter">



      <div id="status">
        {status}
      </div>



    </div>
  );
};

export default Minter;
