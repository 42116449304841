import React from 'react'
import DynamoDB from 'aws-sdk/clients/dynamodb';
import customData from './abi.json';
import env from "react-dotenv";
import { ethers, utils } from "ethers";

import { Alchemy, Network } from 'alchemy-sdk';

require('dotenv').config();

 const dbb = new DynamoDB({accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, region: 'us-west-2'});
 require('dotenv').config();


class MultiVariantProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
    this.autocomplete = null
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateSubmit = this.updateSubmit.bind(this)
    this.triggerCounter = this.triggerCounter.bind(this)
    this.returnButton = this.returnButton.bind(this)
    this.sendOrder = this.sendOrder.bind(this)
    this.toggleSizeChart = this.toggleSizeChart.bind(this)
    this.closeSizeChart = this.closeSizeChart.bind(this)
    this.updateMetadata = this.updateMetadata.bind(this)
    this.togleShipping = this.togleShipping.bind(this)
    this.checkTransaction = this.checkTransaction.bind(this)
    this.burnToken = this.burnToken.bind(this)
    this.chargeShipping = this.chargeShipping.bind(this)
    this.checkingShipping = this.checkingShipping.bind(this)
    this.apiKey = process.env.REACT_APP_TOKEN_APIKEY;
    this.settings = {
      apiKey: this.apiKey,
      network: Network.ETH_MAINNET,
    };
  
    this.web3 = new Alchemy(this.settings);
    this.dbb = new DynamoDB({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, region: 'us-west-2' });
          
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
                    //await provider.send("eth_requestAccounts", []);
                    this.signer = this.provider.getSigner();
                    this.contract = new ethers.Contract("0xd58423fd30b1bb193715bd60b3397065772f7afd",customData.abi,this.signer);


    //this.handleChangeXSmall = this.handleChangeXSmall.bind(this)
    this.handleChangeSmall = this.handleChangeSmall.bind(this)
    this.handleChangeMed = this.handleChangeMed.bind(this)
    this.handleChangeLarge = this.handleChangeLarge.bind(this)
    this.handleChangeXLarge = this.handleChangeXLarge.bind(this)
    this.handleChangeXXLarge = this.handleChangeXXLarge.bind(this)
    this.updateInventory = this.updateInventory.bind(this)
    this.handleChangeTee = this.handleChangeTee.bind(this)
    this.handleChangeHoodie = this.handleChangeHoodie.bind(this)
    this.handleChangeTColor = this.handleChangeTColor.bind(this)
    //this.handleFormSubmit = this.handleFormSubmit.bind(this)
    //this.handleChangeXXXLarge = this.handleChangeXXXLarge.bind(this)

    // this.refreshForm = this.refreshForm.bind(this)
    // this.fireEvent = this.fireEvent.bind(this)
    this.autocomplete = null
  }
  
  

  componentDidMount() {
    this.updateInventory();
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
    
  }

  initialState() {
    return {
      token_id: this.props.tokenid,
      first_name: '',
      last_name: '',
      email:'',
      phone:'',
      street_address: '',
      apartment: '',
      city: '',
      state: '',
      country: '',
      postcode: '',
      button_status:'disabled',
      button_text: 'Select Size',
      googleMapLink: '',
      update_counter: 0,
      selectedTee: '',
      selectedHoodie: '',
      selectedTColor: '',
      selectedHColor: '',
      selected: '',
      shipping: '',
      shippingText: 'Select Rate',
      shippingButton: true,
      hidden: false,
      dis_status: true,
      small: true,
      med: true,
      large: true,
      xlarge: true,
      xxlarge: true,
      wallet: this.props.wallet,
      variant_ids:{
            small: "44297250767140",
            med: "44297250799908",
            large: "44297250832676",
            xlarge: "44297250865444",
            xxlarge: "44297250898212"
          }

    }
  }

  updateInventory = () => {
    


    // var params = {
    //   TableName: 'Short-IDs',
    //   Key: {
    //     'Short-ID': {S: "gid://shopify/ProductVariant/44297250767140"}
    //   }
    // };
    
    // // Call DynamoDB to read the item from the table
    // dbb.getItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //       this.setState({'small': true});
    //     }
    //     else{
    //       console.log("False! - ");
    //       this.setState({['small']: false});
    //     }
    //   }
    // }.bind(this));
    // var params = {
    //   TableName: 'Short-IDs',
    //   Key: {
    //     'Short-ID': {S: "gid://shopify/ProductVariant/44297250799908"}
    //   }
    // };
    
    // // Call DynamoDB to read the item from the table
    // dbb.getItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //       this.setState({['med']: true});
    //     }
    //     else{
    //       console.log("False! - ");
    //       this.setState({['med']: false});
    //     }
    //   }
    // }.bind(this));
    // var params = {
    //   TableName: 'Short-IDs',
    //   Key: {
    //     'Short-ID': {S: "gid://shopify/ProductVariant/44297250832676"}
    //   }
      
    // };
    
    // // Call DynamoDB to read the item from the table
    // dbb.getItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //       this.setState({['large']: true});
    //     }
    //     else{
    //       console.log("False! - ");
    //       this.setState({['large']: false});
    //     }
    //   }
    // }.bind(this));
    // var params = {
    //   TableName: 'Short-IDs',
    //   Key: {
    //     'Short-ID': {S: "gid://shopify/ProductVariant/44297250865444"}
    //   }
      
    // };
    
    // // Call DynamoDB to read the item from the table
    // dbb.getItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //       this.setState({['xlarge']: true});
    //     }
    //     else{
    //       console.log("False! - ");
    //       this.setState({['xlarge']: false});
    //     }
    //   }
    // }.bind(this));
    // var params = {
    //   TableName: 'Short-IDs',
    //   Key: {
    //     'Short-ID': {S: "gid://shopify/ProductVariant/44297250898212"}
    //   }
      
    // };
    
    // // Call DynamoDB to read the item from the table
    // dbb.getItem(params, function(err, data) {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data.Item);
    //     if(data.Item != undefined){
    //       console.log("TRUE!");
    //       this.setState({['xxlarge']: true});
    //     }
    //     else{
    //       console.log("False! - ");
    //       this.setState({['xxlarge']: false});
    //     }
    //   }
    // }.bind(this));
   

  }

  returnButton = () => {
    this.props.buttonFunction();
  }


    updateSubmit = () => {
        let btnStatus = true;

       if(this.state.selectedTee == '' || this.state.selectedHoodie == '' || this.state.selectedTColor == '' || this.state.selectedHColor == ''){
            btnStatus = false
            this.setState({['button_text']: 'Select Size'})
            console.log("CHECKING SUBMIT");
            console.log("1");
       }
       else{
            var min = 1;
            var max = 100;
            var rand =  min + (Math.random() * (max-min));
            this.setState({['update_counter']: rand});
            //this.triggerCounter()
            //console.log(JSON.stringify(this.state))
            
            btnStatus = true
        
        
            if(this.state.street_address == '' || this.state.city == '' || this.state.country == '' || this.state.postcode == ''){
                btnStatus = false;
                this.setState({['button_text']: 'Complete Form'});
                console.log("2");
            }

            if(this.state.googleMapLink != '' && this.state.country != ''){
                btnStatus = true;
                this.setState({['button_text']: 'Submit'});
                console.log("3");
            }

            if(this.state.country == "Ukraine" || this.state.country == "Russia" || this.state.country == "Belarus" || this.state.country == "UA" || this.state.country == "RUS" || this.state.country == "BYS" || this.state.country == "ua" || this.state.country == "rus" || this.state.country == "bys"){
                btnStatus = false;
                this.setState({['button_text']: 'Unsupported Address'});
                console.log("4");
            }
            if(this.state.first_name == '' && this.state.last_name == ''){
                btnStatus = false;
                this.setState({['button_text']: 'Complete Form'});
                console.log("5");
            }
            if(this.state.email.includes('@') == false){
                btnStatus = false;
                this.setState({['button_text']: 'Complete Form'});
                console.log("6");
            }
            if(this.state.phone == ''){
                btnStatus = false ;
                this.setState({['button_text']: 'Complete Form'}); 
                console.log("7");
            }
            
        }
        if( btnStatus == true){
                if(this.state.country === "United States" || this.state.country === "US" || this.state.country === "USA" || this.state.country === "America" || this.state.country === "United States of America" ){
                  this.state.shipping = "0.0078";
                  this.state.hidden = true;
                  this.state.shippingButton = false;
                  this.state.shippingText = "Pay Now";
                }
                else {
                  this.state.shipping = "";//0.033
                  this.state.hidden = false;
                  this.state.shippingButton = true;
                  this.state.shippingText = "Select Rate";
                }
                this.setState({['button_status']: ''});
                this.setState({['button_text']: 'Submit'});
                console.log("BUTTON STATUS: TRUE")}
            
            if( btnStatus == false){
                this.setState({['button_status']: 'disabled'});
                console.log("BUTTON STATUS: False");
            }
                
  }


  handleChange = event => {
    this.setState({[event.target.name]: event.target.value},
        () => {
            var min = 1;
            var max = 100;
            var rand =  min + (Math.random() * (max-min));
            this.setState({['update_counter']: rand})
                this.triggerCounter()
            this.updateSubmit()
        })

  }

  triggerCounter = () =>{
    var min = 1;
   var max = 100;
   var rand =  min + (Math.random() * (max-min));
   this.setState({['update_counter']: rand})
   
  
   //("Updating SUBMIT: ")
   this.updateSubmit()
  }
  

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({['button_status']: 'disabled'});
    //console.log("SUBMIT: "+JSON.stringify(this.state));
    console.log("Hoodie Size: "+ this.state.selectedHoodie  + "Hoodie Color: "+ this.state.selectedHColor + " Tee Size: "+ this.state.selectedTee + "Tee Color: "+ this.state.selectedTColor );

    
    //this.sendOrder();
    this.chargeShipping();
    
    //this.burnToken();
    //this.updateMetadata();
    //this.props.buttonFunction();
  }



  
  handlePlaceSelect = () => {
    
    let addressObject = this.autocomplete.getPlace()
    //("Place Data: Length: "+addressObject.address_components.length+ " data : " +JSON.stringify(addressObject))
    let address = addressObject.address_components
    if(address.length >= 8){
    this.setState({
      ['street_address']: `${address[0].long_name} ${address[1].long_name}`})
      
    this.setState({
        ['city']: `${address[3].long_name}`, 
        ['state']: `${address[5].short_name}`,  
        ['country']: `${address[6].short_name}`,
        ['postcode']: `${address[7].short_name}`,
        ['googleMapLink']: `${addressObject.url}`, 
    },
    () => {
        this.updateSubmit()
    })
    var min = 1;
   var max = 100;
   var rand =  min + (Math.random() * (max-min));
   this.setState({['update_counter']: rand})
     this.triggerCounter()
    //this.fireEvent(address[5].short_name)

    // let placeObj = {
    //       'street_address': `${address[0].long_name} ${address[1].long_name}`,
    //       'city': address[3].long_name,
    //       'state': address[5].short_name,
    //       'country': address[6].short_name,
    //       'zip_code': address[7].short_name,
    //       'googleMapLink': addressObject.url
    //     }
    // this.refreshForm(placeObj)
   
    //console.log("THIS state: "+JSON.stringify(this.state));
  }
  else if(address.length == 7){
    this.setState({
      ['street_address']: `${address[0].long_name} ${address[1].long_name}`})
      
    this.setState({
        ['city']: `${address[3].long_name}`, 
        ['country']: `${address[5].short_name}`,
        ['postcode']: `${address[6].short_name}`,
        ['googleMapLink']: `${addressObject.url}`, 
    },
    () => {
        this.updateSubmit()
    })
  }
  else {
    this.setState({
      ['googleMapLink']: `${addressObject.url}`
    },
    () => {
        this.updateSubmit()
    })

  }
    
  }


  updateMetadata = async () => {
    console.log("Updating Metadata");
    //console.log("FETCH THIS: "+ "customer: first_name: "+ this.state.first_name + " last_name: "+this.state.last_name + " email: "+this.state.email +"} billing_address: {first_name: "+this.state.first_name+" last_name: "+this.state.last_name+" address1: "+this.state.street_address+" address2: "+this.state.apartment+ " phone: "+this.state.phone+" state: "+this.state.city+" city: "+this.state.city+" country: "+this.state.country+" zip: "+this.state.postcode+"} shipping_address: {first_name: "+this.state.first_name+" last_name: "+this.state.last_name+" address1: "+this.state.street_address+" address2: "+this.state.apartment+" phone: "+this.state.phone+" city: "+this.state.city+" state: "+this.state.city+" country: "+this.state.country+ " zip: "+this.state.postcode+ " }, email: "+this.state.email+" tokenID: "+this.props.tokenid);
    // fetch('https://qvgep2eaik.execute-api.us-east-1.amazonaws.com/vbfq_set_physical_claim', {
    //     method: 'POST',
    //     body: JSON.stringify({
    //           "access_key" : "2b9e27bb2Ba1b4a31ad4E00d7a6bd59dF6500b546",
    //           "token_id" : this.props.tokenid   
    //   })
       
    //   })
    //      .then((response) => response.json())
    //      .then((data) => {
    //         console.log("THIS DATA "+data);
    //         // Handle data
    //         this.props.buttonFunction();
    //      })
    //      .catch((err) => {
    //         console.log(err.message);
    //         //console.log("ERROR!");
            
    //      });
        
       }

  sendOrder = async () => {


    
    var sku = "TRE-"+this.state.selectedTColor+"-"+this.state.selectedHColor+"-"+this.state.selectedTee+"-"+this.state.selectedHoodie;
    console.log("SKU: "+sku);

    var shipping = "";
    if(this.state.shipping === "0.0078"){
      shipping = "DOMESTIC";
    }
    else if(this.state.shipping === "0.012"){
      shipping = "INTERNATIONAL DDU";
    }
    else if(this.state.shipping === "0.033"){
      shipping = "INTERNATIONAL DDP";
    }
  //   console.log(JSON.stringify({  
  //     "sku":sku,"name":"Underground Season 1 Merch Bundle","quantity":1,"first_name":this.state.first_name,"last_name":this.state.last_name,"email":this.state.email,"address1":this.state.street_address,"address2":this.state.apartment,"phone":this.state.phone,"state":this.state.state,"city":this.state.city,"country":this.state.country,"zip":this.state.postcode,"tokenID":this.props.tokenid,"walletAddress":this.props.address,"mapsLink":this.state.googleMapLink, "shipping": shipping 
  // }));

    await fetch('https://hooks.zapier.com/hooks/catch/5494090/3opfyym/', {
        method: 'POST',
        body: JSON.stringify({  
            "sku":sku,"name":"Underground Season 1 Merch Bundle","quantity":1,"first_name":this.state.first_name,"last_name":this.state.last_name,"email":this.state.email,"address1":this.state.street_address,"address2":this.state.apartment,"phone":this.state.phone,"state":this.state.state,"city":this.state.city,"country":this.state.country,"zip":this.state.postcode,"tokenID":this.props.tokenid,"walletAddress":this.props.address,"mapsLink":this.state.googleMapLink, "shipping": shipping 
        }),
       
      })
         .then((response) => response.json())
         .then((data) => {
            console.log(data);
            // Handle data
            this.props.buttonFunction();
            
         })
         .catch((err) => {
            console.log(err.message);
            this.props.errorFunction();
         });
       }



       checkTransaction(hash) {
        this.web3.core.getTransaction(hash).then((res) => {
          if(res){
            console.log("Transaction: "+ JSON.stringify(res));
            
              var status = res.blockHash;
              if(status != "" && status != null) {
                console.log("FOUND SUCCESS");
                console.log("");
                this.sendOrder();
              }
              else {
                setTimeout(() => {
                  console.log("Delayed for 1 second.");
                  this.checkTransaction(hash);
                }, 4000);
                
              }
            } 
          });
      }

      checkingShipping(hash) {
        this.web3.core.getTransaction(hash).then((res) => {
          if(res){
          console.log("Shipping Transaction: "+ JSON.stringify(res));

            var status = res.blockHash;
            if(status != "" && status != null) {
              console.log("FOUND SUCCESS");
              console.log("");
              this.burnToken();
              this.props.waitForBurn();
              
            }
            else {
              setTimeout(() => {
                console.log("Delayed for 1 second.");
                this.checkingShipping(hash);
              }, 4000);
              
            }
          }
        });
      }

      chargeShipping = async () => {
        const tx = { 
            to: "0xbf15b271b2f9f45fcd5dfc1167c9c27538bdc6f2",
            value: ethers.utils.parseEther(this.state.shipping)
            
        };
        try{
          this.signer.sendTransaction(tx).then((transaction) => {
              console.dir(transaction);
              console.log("JSON DATA: "+ JSON.stringify(transaction))
              var params = {
                TableName: 'Undergound-Transactions',
                Item: {
                  'transaction': {S: transaction.hash},
                  'tokenId': {S: this.props.tokenid},
                  'walletAddress': {S: this.props.address},
                  'type': {S: "SHIPPING"}
                }
                
              };
              
              
              this.dbb.putItem(params, function(err, data) {
                if (err) {
                  console.log("Error", err);
                } else {
                  console.log("Success");
                    
                }
              });

              this.checkingShipping(transaction.hash);
              this.props.loadingShipping();     

          }).catch((err) => {
           console.log(" THIS ERROR: "+ err);
           this.setState({['button_status']: ''});
         })
        }
        catch (err) {
          console.log("ERROR: "+ err);
        }
    }

  burnToken =  () => {
      try{
      this.contract.redeem(this.props.tokenid,"0x000000000000000000000000000000000000dEaD").then( (res) => {


        console.log("RESPONSE: "+JSON.stringify(res));

        var params = {
          TableName: 'Undergound-Transactions',
          Item: {
            'transaction': {S: res.hash},
            'tokenId': {S: this.props.tokenid},
            'walletAddress': {S: this.props.address},
            'type': {S: "BURN"}
          }
          
        };
        
        
        this.dbb.putItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success");
            
          }
        });

        this.checkTransaction(res.hash);
        this.props.loadingFunction();


       }).catch((err) => {
         console.log(" THIS ERROR: "+ err);
         this.setState({['button_status']: ''});
       })
      }
      catch (err) {
        console.log("ERROR: "+ err);
      }
  }
  
  handleChangeShipping = event => {
    console.log("VARIANT ID: "+event.target.id);
      this.state.shippingButton = false;
      this.state.shippingText = "Pay Now";
      this.setState({['shipping']: event.target.id},
          () => {
              
              var old = document.getElementsByClassName("selected-ship");
              for(var i = 0; i<old.length; i++){
                  old[i].classList.remove("selected-ship");
              }
              event.target.classList.add("selected-ship");
              
          });
           

    
  }


  handleChangeTee = event => {
    console.log("VARIANT ID: "+event.target.id);
    
      this.setState({['selectedTee']: event.target.id},
          () => {
              
              var old = document.getElementsByClassName("selected-tee");
              for(var i = 0; i<old.length; i++){
                  old[i].classList.remove("selected-tee");
              }
              event.target.classList.add("selected-tee");
              this.updateSubmit();
          });
           
        // var submitButton = document.getElementsByClassName('submit-buttom');
        // submitButton[0].classList.remove("disabled");
        // submitButton[0].innerText = "CLAIM YOUR KIT";
        // this.setState({['dis_status']: false});
    
  }

  handleChangeHoodie = event => {
    console.log("VARIANT ID: "+event.target.id);
    
      this.setState({['selectedHoodie']: event.target.id},
          () => {
              
              var old = document.getElementsByClassName("selected-hoodie");
              for(var i = 0; i<old.length; i++){
                  old[i].classList.remove("selected-hoodie");
              }
              event.target.classList.add("selected-hoodie");
              this.updateSubmit();
          });
           
        // var submitButton = document.getElementsByClassName('submit-buttom');
        // submitButton[0].classList.remove("disabled");
        // submitButton[0].innerText = "CLAIM YOUR KIT";
        // this.setState({['dis_status']: false});
    
  }

  handleChangeTColor = event => {
    console.log("VARIANT ID: "+event.target.id);
    
      this.setState({['selectedTColor']: event.target.id},
          () => {
              
              var old = document.getElementsByClassName("selected-T-color");
              for(var i = 0; i<old.length; i++){
                  old[i].classList.remove("selected-T-color");
              }
              event.target.classList.add("selected-T-color");
              this.updateSubmit();
          });
           
        // var submitButton = document.getElementsByClassName('submit-buttom');
        // submitButton[0].classList.remove("disabled");
        // submitButton[0].innerText = "CLAIM YOUR KIT";
        // this.setState({['dis_status']: false});
    
  }

  handleChangeHColor = event => {
    console.log("VARIANT ID: "+event.target.id);
    
      this.setState({['selectedHColor']: event.target.id},
          () => {
              
              var old = document.getElementsByClassName("selected-H-color");
              for(var i = 0; i<old.length; i++){
                  old[i].classList.remove("selected-H-color");
              }
              event.target.classList.add("selected-H-color");
              this.updateSubmit();
          });
           
        // var submitButton = document.getElementsByClassName('submit-buttom');
        // submitButton[0].classList.remove("disabled");
        // submitButton[0].innerText = "CLAIM YOUR KIT";
        // this.setState({['dis_status']: false});
    
  }
      

      handleChangeSmall = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.small == false){
          this.setState({['selected']: "44297250767140"},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
                  this.updateSubmit();
              });
               
            // var submitButton = document.getElementsByClassName('submit-buttom');
            // submitButton[0].classList.remove("disabled");
            // submitButton[0].innerText = "CLAIM YOUR KIT";
            // this.setState({['dis_status']: false});
        }
      }

      handleChangeMed = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.med == false){
          this.setState({['selected']: "44297250799908"},
              () => {
                  
                  var old = document.getElementsByClassName("selected-tee");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected-tee");
                  }
                  event.target.classList.add("selected-tee");
                  this.updateSubmit();
              });
               
            // var submitButton = document.getElementsByClassName('submit-buttom');
            // submitButton[0].classList.remove("disabled");
            // submitButton[0].innerText = "CLAIM YOUR KIT";
            // this.setState({['dis_status']: false});
        }
      }

      handleChangeLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.large == false){
          this.setState({['selected']: "44297250832676"},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
                  this.updateSubmit();
              });
              
            // var submitButton = document.getElementsByClassName('submit-buttom');
            // submitButton[0].classList.remove("disabled");
            // submitButton[0].innerText = "CLAIM YOUR KIT";
            // this.setState({['dis_status']: false});
        }
      }

      handleChangeXLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xlarge == false){
          this.setState({['selected']: "44297250865444"},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
                  this.updateSubmit();
              });
              
            // var submitButton = document.getElementsByClassName('submit-buttom');
            // submitButton[0].classList.remove("disabled");
            // submitButton[0].innerText = "CLAIM YOUR KIT";
            // this.setState({['dis_status']: false});
        }
      }

      handleChangeXXLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xxlarge == false){
          this.setState({['selected']: "44297250898212"},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
                  this.updateSubmit();
              });
               
            // var submitButton = document.getElementsByClassName('submit-buttom');
            // submitButton[0].classList.remove("disabled");
            // submitButton[0].innerText = "CLAIM YOUR KIT";
            // this.setState({['dis_status']: false});
        }
      }

      
     
       toggleSizeChart() {
        var target = document.getElementById("size-chart-target");
        //console.log("Target: "+target.classList.contains("hidden"));
        if (target.classList.contains("hidden")) {
          target.classList.remove("hidden");
        }
    
      }
       closeSizeChart() {
        var target = document.getElementById("size-chart-target");
        //console.log("Target: "+target.classList.contains("hidden"));
        if (target.classList.contains("hidden")) {
    
        }
        else {
          target.classList.add("hidden");
        }
      }



      togleShipping() {
        var shippingModal = document.getElementById("shipping-target");
        if (shippingModal.classList.contains("open")) {
          shippingModal.classList.remove("open");
        }
        else {
          shippingModal.classList.add("open");
        }
      }



  render() {
    return(
        <div>
            <div className="product-container">
                    <div className="split-parent">
                    <div className="split-child img-child">
                        <img src="bundle-v2.png" loading="lazy" sizes=""  alt="" className="product-img"></img>
                    </div>
                    <div className="split-child">
                        <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile product">The Underground Merch Bundle</h1>
                        <p className="paragraph">Underground is providing a complimentary bundle of merch for all existing Season 1 Underground Community members (just pay for shipping)!  This marks the debut clothing collection produced by Underground. The bundle includes a stylish hat, a comfortable t-shirt, and a cozy hoodie all featuring the iconic Underground logo.</p>
                        <div className="product-form">
                        <div className="label-parent"><p className="variant-label">Select Size</p><p className="size-chart-button" onClick={this.toggleSizeChart}>Size Chart</p></div>
                      <div className="variant-group">
                        
                        <div className="variant-parent">
                                <div className="label-parent"><p className="variant-label">Select Hoodie Color</p></div>
                                <div className="buttons-parent">
                                    <button id="BH" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHColor}>Black</button>
                                    <button id="EH" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHColor}>Ecru</button>
                                    
                                </div>
                            </div>
                            <div className="variant-parent">
                                <div className="label-parent"><p className="variant-label">Select Hoodie Size</p></div>
                                <div className="buttons-parent">
                                    <button id="1" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHoodie}>S</button>
                                    <button id="2" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHoodie}>M</button>
                                    <button id="3" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHoodie}>L</button>
                                    <button id="4" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHoodie}>XL</button>
                                    <button id="5" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeHoodie}>2XL</button>
                                </div>
                            </div>
                          </div>
                          <div className="variant-group">
                            <div className="variant-parent">
                                <div className="label-parent"><p className="variant-label">Select Tee Color</p></div>
                                <div className="buttons-parent">
                                    <button id="BT" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTColor}>Black</button>
                                    <button id="ET" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTColor}>Ecru</button>
                                    
                                </div>
                            </div>
                            <div className="variant-parent tee-size">
                                <div className="label-parent"><p className="variant-label">Select Tee Size</p></div>
                                <div className="buttons-parent">
                                    <button id="1" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTee}>S</button>
                                    <button id="2" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTee}>M</button>
                                    <button id="3" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTee}>L</button>
                                    <button id="4" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTee}>XL</button>
                                    <button id="5" className="variant-button tee-variant" disabled={false} onClick={this.handleChangeTee}>2XL</button>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div> 
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" className="heading mobile product-specs">Product Specs</h1>
                <div className="split-parent">
                  <div className="split-child">
                    <p className="paragraph specs"><strong>T-Shirt -</strong><br></br>Regular fit<br></br>Crew neck<br></br>Mid-weight, 5.3 oz/yd2, 28-singles<br></br>100% combed cotton (heathers 15% viscose)<br></br>Neck ribbing, side seamed, shoulder to shoulder tape, double needle hems, preshrunk to minimize shrinkage<br></br><br></br><strong>Hat -</strong><br></br>One size fits most<br></br>Six panels, unstructured, pre-curved bill<br></br>Self-fabric back strap with adjustable buckle closure<br></br>100% Cotton Twill</p>
                  </div>
                  <div className="split-child">
                    <p className="paragraph specs"><strong>Hoodie -</strong><br></br>Relaxed, oversized fit with dropped shoulders<br></br>Heavyweight, 11.8 oz/yd2<br></br>CVC Fleece, 80% cotton 20% polyester<br></br>Inset sleeves, 2x2 ribbing on neck, cuff, and hem</p>
                  </div>
                </div>
                
                  
            </div>
        <div className="form-parent">
            <h1 className="heading mobile">Claim Now</h1>
            <p className="paragraph">Fill out the form below to claim your reward. The burn-to-claim will process once you click the "Submit" button at the end of the form.</p>
            <div className="checkout-form">
                <div className='form-imput-parent'>
                <div className="input-parent first">
                    <label htmlFor="first_name" className="">First Name *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input 
                        id='name-input'
                        className='name-input'
                        name={"first_name"}
                        placeholder={"John"}
                        value={this.state.first_name}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                <div className="input-parent">
                    <label htmlFor="last_name" className="">Last Name *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input 
                        className='name-input'
                        name={"last_name"}
                        placeholder={"Doe"}
                        value={this.state.last_name}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                </div>
                <div className="input-parent">
                <label htmlFor="email" className="">Email *</label>
                <div className="input-div-parent">
                    <div className="input-div">
                    <input 
                        name={"email"}
                        placeholder={"example@domain.com"}
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="input-parent">
                <label htmlFor="phone" className="">Phone *</label>
                <div className="input-div-parent">
                    <div className="input-div">
                    <input 
                        name={"phone"}
                        placeholder={"+1-999-999-9999"}
                        value={this.state.phone}
                        onChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="input-parent">
                <label htmlFor="street_address" className="">Address *</label>
                <div className="input-div-parent">
                    <div className="input-div">
                    <input 
                        id="autocomplete"
                        className="input-field"
                        ref="input"
                        type="text"
                        name={"street_address"}
                        value={this.state.street_address}
                        placeholder={"123 Cool Cats Place"}
                        onChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className='form-imput-parent'>
                <div className="input-parent first">
                    <label htmlFor="apartment" className="">Apartment, suite, unit (optional)</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input 
                        name={"apartment"}
                        value={this.state.apartment}
                        placeholder={"#1234"}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                <div className="input-parent">
                    <label htmlFor="city" className="">City/Town *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input 
                        name={"city"}
                        value={this.state.city}
                        placeholder={"Catsville"}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                </div>
                <div className='form-imput-parent'>
                <div className="input-parent first">
                    <label htmlFor="state" className="">State/Province/Region *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input
                        name={"state"}
                        id="form-state"
                        value={this.state.state}
                        placeholder={"State"}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                <div className="input-parent">
                    <label htmlFor="postcode" className="">Postal/Zip Code *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input 
                        name={"postcode"}
                        value={this.state.postcode}
                        placeholder={"12345"}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                </div>
                <div className="input-parent">
                    <label htmlFor="country" className="">Country *</label>
                    <div className="input-div-parent">
                    <div className="input-div">
                        <input
                        name={"country"}
                        value={this.state.country}
                        placeholder={"Country"}
                        onChange={this.handleChange}
                        />
                    </div>
                    </div>
                </div>
                
                

                <button onClick={this.togleShipping} disabled={this.state.button_status}>{this.state.button_text}</button>
            </div>
            
        </div>



        <div id="shipping-target" className='total-cost-parent'>
          
          <div className='lineitem-parent'>
          <div className='shipping-close' onClick={this.togleShipping}><img src="close-24.png" loading="lazy" sizes=""  alt="" className="close-img"></img></div>
            <div className='shipping-description'>
              <h1 className="heading mobile">Shipping Charges</h1>
              <p className='shipping-text'>
                The checkout form indicates that you are shipping this bundle to {this.state.country}. Click the button below to pay shipping with your connected wallet.                  
              </p>
            </div>
            <div hidden={this.state.hidden} className="variant-parent shipping-variant">
                                <div className="label-parent shipping-label"><p className="variant-label">Select Available Shipping Method</p></div>
                                <div className="buttons-parent shipping-parent">
                                    <div className='button-grouping'>
                                      <button id="0.012" className="variant-button shipping" disabled={false} onClick={this.handleChangeShipping}></button>
                                      <h4 className='button-label'>Taxes & Duties Due on Arrival <strong>(0.012 ETH)</strong></h4>
                                    </div>
                                    <div className='button-grouping'>
                                      <button id="0.033" className="variant-button shipping" disabled={false} onClick={this.handleChangeShipping}></button>
                                      <h4 className='button-label'>Taxes & Duties Paid <strong>(0.033 ETH)</strong></h4>
                                    </div>
                                    
                                    
                                </div>
                            </div>
            <div className='cost-lineitem'>
              <p className='cost-label'>Shipping</p>
              <p className="cost-value">{this.state.shipping} ETH</p>
            </div>
            <div className='pay-button'>
              <button  onClick={this.handleSubmit} disabled={this.state.shippingButton} >{this.state.shippingText}</button>
            </div>
          </div>
        </div>

        <div id="size-chart-target" className="sizechart-parent hidden" onClick={this.closeSizeChart}>
             <div className="size-parent-container">
               <div className="modal-size-chart">
                 <h1 className="size-chart-heading">Size Charts</h1>
                 <h3>Hoodie</h3>
                 <div className="table">
                   <div className="table-row first">
                     <div className="table-cell first">Size</div>
                     <div className="table-cell">Body Width (in.)</div>
                     <div className="table-cell">Body Length (in.)</div>
                     
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">S</div>
                     <div className="table-cell">23.5</div>
                     <div className="table-cell">28</div>
                     
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">M</div>
                     <div className="table-cell">24.75</div>
                     <div className="table-cell">29</div>
                     
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">L</div>
                     <div className="table-cell">26</div>
                     <div className="table-cell">30</div>
                    
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">XL</div>
                     <div className="table-cell">27.25</div>
                     <div className="table-cell">31</div>
                     
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">2XL</div>
                     <div className="table-cell">28.25</div>
                     <div className="table-cell">32</div>
                     
                   </div>
                   
                 </div>
                 <h3 className='tee-parent'>Tee</h3>
                 <div className="table">
                   <div className="table-row first">
                     <div className="table-cell first">Size</div>
                     <div className="table-cell">Body Width (in.)</div>
                      
                     <div className="table-cell">Body Length (in.)</div>
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">S</div>
                     <div className="table-cell">18.5</div>
                     <div className="table-cell">28</div>
                      
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">M</div>
                     <div className="table-cell">20.5</div>
                     <div className="table-cell">29.5</div>
                      
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">L</div>
                     <div className="table-cell">22.25</div>
                     <div className="table-cell">31</div>
                      
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">XL</div>
                     <div className="table-cell">24</div>
                     <div className="table-cell">32.25</div>
                      
                   </div>
                   <div className="table-row">
                     <div className="table-cell first">2XL</div>
                     <div className="table-cell">25.25</div>
                     <div className="table-cell">33</div>
                      
                   </div>
                   
                 </div>
               </div>
               <div className="close"><img src="close-24.png" loading="lazy" sizes=""  alt="" className="close-img"></img></div>
             </div>

           </div>
      </div>
    )
  }

}

export default MultiVariantProduct