import React from 'react'
import env from "react-dotenv";
import DynamoDB from 'aws-sdk/clients/dynamodb';
import { ethers } from 'ethers';

 const dbb = new DynamoDB({accessKeyId: env.AWS_ACCESS_KEY_ID, secretAccessKey: env.AWS_SECRET_ACCESS_KEY, region: 'us-west-2'});
 require('dotenv').config();

 const apiKey = env.REACT_APP_TOKEN_APIKEY;

//  const web3 = createAlchemyWeb3(
//   `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`,
// );


class ProductForm extends React.Component {

    

    constructor(props) {
        super(props)
        this.state = this.initialState()
        this.handleChangeXSmall = this.handleChangeXSmall.bind(this)
        this.handleChangeSmall = this.handleChangeSmall.bind(this)
        this.handleChangeMed = this.handleChangeMed.bind(this)
        this.handleChangeLarge = this.handleChangeLarge.bind(this)
        this.handleChangeXLarge = this.handleChangeXLarge.bind(this)
        this.handleChangeXXLarge = this.handleChangeXXLarge.bind(this)
        this.handleChangeXXXLarge = this.handleChangeXXXLarge.bind(this)
        this.updateInventory = this.updateInventory.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        
      }
      
      
    
      componentDidMount() {
        
        
        
        this.updateInventory();
          
        
        
        
      }
    
      initialState() {
        return {
          token_id: this.props.tokenid,
          selected: '',
          wallet: this.props.wallet,
          dis_status: true,
          xsmall: true,
          small: true,
          med: true,
          large: true,
          xlarge: true,
          xxlarge: true,
          xxxlarge: true,
          variant_ids:{
            xsmall: "44088802640180",
            small: "44052540817716",
            med: "44052540850484",
            large: "44052540883252",
            xlarge: "44052540916020",
            xxlarge: "44052540948788",
            xxxlarge: "44088803524916"
          }
        }
      }

      updateInventory = () => {
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44088802640180"}
          }
          
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['xsmall']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['xsmall']: false});
            }
          }
        }.bind(this));


        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44052540817716"}
          }
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({'small': true});
            }
            else{
              console.log("False! - ");
              this.setState({['small']: false});
            }
          }
        }.bind(this));
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44052540850484"}
          }
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['med']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['med']: false});
            }
          }
        }.bind(this));
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44052540883252"}
          }
          
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['large']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['large']: false});
            }
          }
        }.bind(this));
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44052540916020"}
          }
          
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['xlarge']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['xlarge']: false});
            }
          }
        }.bind(this));
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44052540948788"}
          }
          
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['xxlarge']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['xxlarge']: false});
            }
          }
        }.bind(this));
        var params = {
          TableName: 'A-NFT',
          Key: {
            'a-nft-key': {S: "gid://shopify/ProductVariant/44088803524916"}
          }
          
        };
        
        // Call DynamoDB to read the item from the table
        dbb.getItem(params, function(err, data) {
          if (err) {
            console.log("Error", err);
          } else {
            console.log("Success", data.Item);
            if(data.Item != undefined){
              console.log("TRUE!");
              this.setState({['xxxlarge']: true});
            }
            else{
              console.log("False! - ");
              this.setState({['xxxlarge']: false});
            }
          }
        }.bind(this));
    
      }

      handleChangeXSmall = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xsmall == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeSmall = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.small == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeMed = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.med == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.large == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeXLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xlarge == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeXXLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xxlarge == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleChangeXXXLarge = event => {
        console.log("VARIANT ID: "+event.target.id);
        if(this.state.xxxlarge == false){
          this.setState({['selected']: event.target.id},
              () => {
                  
                  var old = document.getElementsByClassName("selected");
                  for(var i = 0; i<old.length; i++){
                      old[i].classList.remove("selected");
                  }
                  event.target.classList.add("selected");
              });
            var submitButton = document.getElementsByClassName('submit-buttom');
            submitButton[0].classList.remove("disabled");
            submitButton[0].innerText = "CLAIM YOUR KIT";
            this.setState({['dis_status']: false});
        }
      }

      handleFormSubmit = async event => {
        event.preventDefault();
        try{
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          //await provider.send("eth_requestAccounts", []);
          

          const signer = provider.getSigner();
          await signer.getAddress().then(async (res) => {
            console.log("RESPONSE: "+ res.toLowerCase() + " CONNECTED: "+ this.state.wallet );
            if(res.toLowerCase() === this.props.wallet){
              await signer.signMessage("Confirm merch claim on token "+this.state.token_id).then((res) => {
                console.log("SUCCESS: "+res);
                var url="";
                    if(this.state.selected == "xsmall"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xsmall +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "small"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.small +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "med"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.med +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "large"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.large +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "xlarge"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xlarge +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "xxlarge"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xxlarge +":1?note="+this.state.token_id;
                    }
                    if(this.state.selected == "xxxlarge"){
                      var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xxxlarge +":1?note="+this.state.token_id;
                    }
                  
            
                    
                    console.log("URL: "+ url);
                    window.location.href = url;
            
                    }).catch((err) => {
                            console.error('Error:', err);
                            
                  });
            }
            else{
              this.props.error();

            }
          })
        }
        catch(err){
          console.log(err);
        }



        
        
          // const msgParams = JSON.stringify({
          //   domain: {
          //     // Defining the chain aka Rinkeby testnet or Ethereum Main Net
          //     chainId: 1,
          //     // Give a user friendly name to the specific contract you are signing for.
          //     name: 'WAGMI Claim',
          //     // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
          //     verifyingContract: 'wagmi.topdrawermerch.com',
          //     // Just let's you know the latest version. Definitely make sure the field name is correct.
          //     version: '1',
          //   },
        
          //   // Defining the message signing data content.
          //   message: {
          //     /*
          //      - Anything you want. Just a JSON Blob that encodes the data you want to send
          //      - No required fields
          //      - This is DApp Specific
          //      - Be as explicit as possible when building out the message schema.
          //     */
          //     contents: 'Approve merch claim on token',
          //     attachedMoneyInEth: 0
          //     //,
          //     // from: {
          //     //   name: 'Cow',
          //     //   wallets: [
          //     //     '0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826',
          //     //     '0xDeaDbeefdEAdbeefdEadbEEFdeadbeEFdEaDbeeF',
          //     //   ],
          //     //  }
          //      //,
          //     // to: [
          //     //   {
          //     //     name: 'Bob',
          //     //     wallets: [
          //     //       '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB',
          //     //       '0xB0BdaBea57B0BDABeA57b0bdABEA57b0BDabEa57',
          //     //       '0xB0B0b0b0b0b0B000000000000000000000000000',
          //     //     ],
          //     //   },
          //     // ],
          //   },
          //   // Refers to the keys of the *types* object below.
          //   primaryType: 'Claim',
          //   types: {
          //     // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
          //     EIP712Domain: [
          //       { name: 'name', type: 'string' },
          //       { name: 'version', type: 'string' },
          //       { name: 'chainId', type: 'uint256' },
          //       { name: 'verifyingContract', type: 'address' },
          //     ],
          //     // Not an EIP712Domain definition
          //     Group: [
          //       { name: 'name', type: 'string' },
          //       { name: 'members', type: 'Person[]' },
          //     ],
          //     // Refer to PrimaryType
          //     Mail: [
          //       { name: 'from', type: 'Person' },
          //       { name: 'to', type: 'Person[]' },
          //       { name: 'contents', type: 'string' },
          //     ],
          //     // Not an EIP712Domain definition
          //     Person: [
          //       { name: 'name', type: 'string' },
          //       { name: 'wallets', type: 'address[]' },
          //     ],
          //   },
          // });
        
          // var from = await web3.eth.getAccounts();
        
          // var params = [from[0], msgParams];
          // var method = 'eth_signTypedData_v4';
        
          // web3.currentProvider.send(
          //   {
          //     method,
          //     params,
          //     from: from[0],
          //   },
          //   function (err, result) {
          //     if (err) return console.dir(err);
          //     if (result.error) {
          //       alert(result.error.message);
          //     }
          //     if (result.error) return console.error('ERROR', result);
          //     console.log('TYPED SIGNED:' + JSON.stringify(result.result));
        
          //     // const recovered = sigUtil.recoverTypedSignature_v4({
          //     //   data: JSON.parse(msgParams),
          //     //   sig: result.result,
          //     // });
        
          //     // if (
          //     //   ethUtil.toChecksumAddress(recovered) === ethUtil.toChecksumAddress(from)
          //     // ) {
          //     //   alert('Successfully recovered signer as ' + from);
          //     // } else {
          //     //   alert(
          //     //     'Failed to verify signer when comparing ' + result + ' to ' + from
          //     //   );
          //     // }
          //   }
          // );
       






        // var url="";
        // if(this.state.selected == "xsmall"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xsmall +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "small"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.small +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "med"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.med +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "large"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.large +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "xlarge"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xlarge +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "xxlarge"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xxlarge +":1?note="+this.state.token_id;
        // }
        // if(this.state.selected == "xxxlarge"){
        //   var url = "https://wagmi-united-shop.myshopify.com/cart/"+ this.state.variant_ids.xxxlarge +":1?note="+this.state.token_id;
        // }
       

        
        // console.log("URL: "+ url);
        // window.location.href = url;
      }

      render() {
        return(
            <div className="product-form">
                <div className="variant-parent">
                    <div className="label-parent"><p className="variant-label">Select Size</p><p className="size-chart-button" onClick={this.props.sizeChart}>Size Chart</p></div>
                    <div className="buttons-parent">
                        <button id="xsmall" className="variant-button" disabled={this.state.xsmall} onClick={this.handleChangeXSmall}>XS</button>
                        <button id="small" className="variant-button" disabled={this.state.small} onClick={this.handleChangeSmall}>S</button>
                        <button id="med" className="variant-button" disabled={this.state.med} onClick={this.handleChangeMed}>M</button>
                        <button id="large" className="variant-button" disabled={this.state.large} onClick={this.handleChangeLarge}>L</button>
                        <button id="xlarge" className="variant-button" disabled={this.state.xlarge} onClick={this.handleChangeXLarge}>XL</button>
                        <button id="xxlarge" className="variant-button" disabled={this.state.xxlarge} onClick={this.handleChangeXXLarge}>2XL</button>
                        <button id="xxxlarge" className="variant-button" disabled={this.state.xxxlarge} onClick={this.handleChangeXXXLarge}>3XL</button>
                    </div>
                </div>
                <button id="walletButton" className="next-product submit-buttom disabled" disabled={this.state.dis_status} onClick={this.handleFormSubmit}>SELECT SIZE</button>
            </div>
        )
      }


}

export default ProductForm